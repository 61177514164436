<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedCsvActions"
        :default-value="properties.action"
        step-label="csv"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-source`"
        v-model="properties.source"
        outlined
        dense
        data-cy="csv-source"
        :label="$lang.labels.source"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        class="required-asterisk"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        data-cy="csv-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        class="required-asterisk"
      />
    </v-col>

    <v-col cols="12">
      <v-select
        :key="`${step.id}-delimiter`"
        v-model="properties.delimiter"
        :items="delimiters"
        :label="$lang.labels.delimiter"
        outlined
        dense
        required
        data-cy="csv-delimiter"
        :readonly="!canEdit"
        @change="properties.delimiter !== 'CUSTOM' ? properties.customDelimiter = '' : ''"
      />
    </v-col>

    <v-col v-if="properties.delimiter === 'CUSTOM'" cols="12">
      <v-text-field
        :key="`${step.id}-customDelimiter`"
        v-model="properties.customDelimiter"
        outlined
        dense
        data-cy="csv-customDelimiter"
        :label="$lang.labels.customDelimiter"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      />
    </v-col>

    <v-col cols="12">
      <v-select
        :key="`${step.id}-qualifier`"
        v-model="properties.qualifier"
        :items="qualifiers"
        :label="$lang.labels.qualifier"
        outlined
        dense
        required
        data-cy="csv-qualifier"
        :readonly="!canEdit"
      />
    </v-col>

    <v-col cols="12">
      <v-checkbox
        :key="`${step.id}-includeHeaders`"
        v-model="properties.includeHeaders"
        :label="$lang.labels.includeHeaders"
        :readonly="!canEdit"
      />
    </v-col>

    <v-col cols="12" class="pb-3">
      <add-key-value
        :key="`${step.id}-filters`"
        type="filters"
        :can-edit="canEdit"
        step-type="CSV"
        :data="properties.filters"
        :single-step="step"
        @dataChanged="handleChange('filters', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ActionSelect } from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'

import { CSV_ACTIONS } from './constants'
import { definitions } from '@/utils/definitions'

export default {
  name: 'CsvStep',
  components: {
    ActionSelect,
    AddKeyValue
  },
  mixins: [StepMixin],
  data() {
    return {
      delimiters: definitions.CsvStepProperties.properties.delimiter.enum,
      qualifiers: definitions.CsvStepProperties.properties.qualifier.enum
    }
  },
  computed: {
    formattedCsvActions() {
      return CSV_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  }
}
</script>
